import { render, staticRenderFns } from "./ContBox.vue?vue&type=template&id=4abd1cbe&scoped=true"
var script = {}
import style0 from "./ContBox.vue?vue&type=style&index=0&id=4abd1cbe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4abd1cbe",
  null
  
)

export default component.exports